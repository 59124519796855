import { useState } from "react"
import "./Pagination.scss"

function Pagination(props) {
    const [currentValue,setCurrentValue] = useState(1)
    const onClickIterate = (event) => {
        const itemValue = event.target.value
        const newValue = currentValue+itemValue>0&&currentValue+itemValue<=props.items?currentValue+itemValue:currentValue
        // const newValue = currentValue+itemValue
        setCurrentValue(newValue)
        props.pageHandler(newValue)
    }
    const onClickFirstLastPage = (event) =>{
        const itemValue = event.target.value
        setCurrentValue(itemValue)
        props.pageHandler(itemValue)
    }

    const onChangeHandler = (event) => {
        const itemValue = event.target.value?parseInt(event.target.value):""
        if (itemValue!==""){
            setTimeout(() => {setCurrentValue(itemValue);props.pageHandler(itemValue)},500)
        }
    }

    return (
        <div className="pagination">
            <ul className="pagination__list">
                {currentValue!==1?(
                    <>
                        <li key={"index_zero"} onClick={onClickFirstLastPage} className="pagination__list__item" value={1}>&lt;&lt;</li>
                        <li key={"decrese"} onClick={onClickIterate} className="pagination__list__item" value={-1}>&lt;</li>
                    </>
                ):null}
                <input type="text" onChange={onChangeHandler} className="pagination__list__input" value={currentValue} placeholder={currentValue}/>
                {currentValue!==props.items?
                (
                    <>
                        <li key={"increse`"} onClick={onClickIterate} className="pagination__list__item" value={1}>&gt;</li>
                        <li key={"index_last"} onClick={onClickFirstLastPage} className="pagination__list__item" value={props.items}>&gt;&gt;</li>
                    </>
                ):null}
            </ul>
        </div>
    )
}

export default Pagination