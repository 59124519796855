import {UserSidebar} from "../components/Sidebar/Sidebar";
import React from "react";
import call, {encodeObjectPath} from "../api";
import "pyloncss/css/pylon.css";
import BaseApp from "./BaseApp";
import {BreakGlassLaunchConfigSection} from "../components/LaunchConfigSection/LaunchConfigSection";
import moment from "moment";
import {
    AppSelectField,
    UserSelectField
} from "../components/Fields/DropdownSelectField";
import {NumberField} from "../components/Fields/TextField";
import ItemTable from "../components/ItemTable/ItemTable";


const NEW_ITEM = JSON.stringify({
    "duration": 240
})


export default class BreakGlass extends BaseApp {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            loaded: false,
            users: [],
            sessions: [],
            history: [],
            item: JSON.parse(NEW_ITEM)
        };
    }

    update() {
        this.getSessions();
        this.getUsers();
    }

    getSessions() {
        call(
            "GET",
            "/generic/break_glass",
            (status, content) => {
                if (status !== 200) {
                    this.sendNotification("error", "Error while fetching break glass sessions", content.message, 30);
                    return;
                }
                let newValue = Object.values(content.history).map((item)=>{
                    return item
                })
                this.setState({
                    sessions: Object.values(content.active_sessions), 
                    history: newValue,
                    loaded: true
                });
            }
        )
    }

    getUsers() {
        call(
            "GET",
            "/generic/user-ad",
            (status, ad) => {
                if (status !== 200) {
                    this.sendNotification("error", "Error while fetching users", ad.message, 30);
                    return;
                }

                call(
                    "GET",
                    "/generic/user-pool",
                    (status, pool) => {
                        if (status !== 200) {
                            this.sendNotification("error", "Error while fetching users", pool.message, 30);
                            return;
                        }

                        this.setState({users: [...Object.values(ad), ...Object.values(pool)]});
                    }
                )
            }
        )
    }

    onSessionClose(e, session) {
        call(
            "DELETE",
            "break_glass",
            (status, content) => {
                if (status !== 200) {
                    this.sendNotification("error", "Error while closing a session.", content.message, 30);
                    return;
                }

                this.sendNotification("info", "Session is closing", "Break glass session is shutting down.", 5);

                setTimeout(() => this.getSessions(), 1000);
            },
            {
                "path": session.path
            }
        );
    }


    updateField(name, value) {
        const item_cpy = JSON.parse(JSON.stringify(this.state.item));
        item_cpy[name] = value;
        this.setState({"item": item_cpy});
    }

    validate(name, value) {
        if (name === "user") {
            if (!value || value.length < 1) {
                return "Can't be empty";
            }
        }

        if (name === "launch_configs") {
            if (!value || value.length < 1) {
                return "Can't be empty";
            }
        }

        if (name === "duartion") {
            if (value <= 0) {
                return "Can't be 0"
            }

            if (value > 24 * 60) {
                return "Can't be longer than 24 hours."
            }
        }
    }

    createBreakGlass(e) {
        if (this.validate("user", this.state.item.user) || this.validate("launch_configs", this.state.item.launch_configs)) {
            this.sendNotification(
                "error",
                "Can't create a break glass",
                `Can't save until all validation errors are resolved.`,
                10
            );
            return
        }

        call(
            "POST",
            "break_glass",
            (status, content) => {
                if (status !== 200) {
                    this.sendNotification("error", "Error while fetching break glass sessions", content.message, 30);
                    return;
                }

                this.setState({item: JSON.parse(NEW_ITEM)});
                this.sendNotification("info", "Break glass granted.");
                this.getSessions();
            },
            {
                ...this.state.item,
                "duration": this.state.item.duration * 60
            }
        )
    }

    activeBreakGlassContent() {
        const result = [];

        if (!this.state.users)
            return [];

        for (const i in this.state.sessions) {
            const session = this.state.sessions[i];
            const user = this.state.users[this.state.users.findIndex((item) => item.path === session.user)];
            const applications = [];

            for (const j in session.permissions) {
                const app = session.permissions[j];
                let name = "";
                if (typeof app.account === "object")
                    name = app.account.name || app.account.path;
                else if (typeof app.vpc === "object")
                    name = typeof app.vpc.account === "object" ? app.vpc.account.name : app.vpc.name || app.vpc.path;

                applications.push(`${app.name || app.path} at ${name}`);
            }

            result.push({
                key: session.path,
                name: typeof user !== "undefined" ? user.username || user.name || user.email : session.user,
                email: typeof user !== "undefined" ? user.email : "",
                applications: applications.join(", "),
                time: moment(session.expires_at).fromNow(true),
                onAppStop: (e) => this.onSessionClose(e, session),
            });
        }

        return result;
    }


    /*
     * Render
     */
    
    getSidebar() {
        return (
            <UserSidebar
                short={this.state.isMenuShort}
                onCloseAll={(e) => this.onCloseAll(e)}
                capabilities={this.getCapabilities()}
            />)
    }


    getContent() {
        return this.state.loaded?[
            <vstack className="item-edit-page" spacing="s" key="new-bg">
                <span
                    className="header">break glass - grant temporary access</span>
                <vstack className="item-form" spacing="xxs">
                    <UserSelectField
                        value={this.state.item.user}
                        onChange={(value) => this.updateField("user", value)}
                        validation={(value) => this.validate("user", value)}
                        sendNotification={(t, ti, te, ex) => this.sendNotification(t, ti, te, ex)}
                        name="user"
                    />
                    <AppSelectField
                        value={this.state.item.policies}
                        onChange={(value) => this.updateField("launch_configs", value)}
                        validation={(value) => this.validate("launch_configs", value)}
                        sendNotification={(t, ti, te, ex) => this.sendNotification(t, ti, te, ex)}
                        name="launch_configs"
                        displayName="Grant access to"
                        minLength={1}
                    />
                    <NumberField
                        value={240}
                        onChange={(value) => this.updateField("duration", value)}
                        validation={(value) => this.validate("duration", value)}
                        sendNotification={(t, ti, te, ex) => this.sendNotification(t, ti, te, ex)}
                        name="duration"
                        displayName="Duration (minutes)"
                    />
                </vstack>
                <hstack spacing="s">
                    <spacer/>
                    <div className="button button-save" onClick={(e) => this.createBreakGlass(e)}>Save</div>
                </hstack>
            </vstack>,
            <BreakGlassLaunchConfigSection
                key="Granted break glass sessions"
                title="Granted break glass sessions"
                columns={2}
                search={this.state.search}
                content={this.state.users.length && this.state.sessions.length ? this.activeBreakGlassContent() : []}/>,
            <ItemTable
                columns={["grant_user", "grant_user_type", "user", "user_type", "permissions", "expiry_date", "grant_date"]}
                data={this.state.history}
                title={"Granted Break Glass History"}
                noCreate={true}
            />
        ]:null
    }
}