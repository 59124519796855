import React from "react";
import BaseEditItem from "./BaseEditItem";
import TextField from "../Fields/TextField";
import "./EditItem.scss";
import DropdownSelectField from "../Fields/DropdownSelectField";


export class GroupMappingEdit extends BaseEditItem {
    FIELDS = [
        {
            element: TextField,
            name: "description",
        },
        {
            element: TextField,
            name: "ad_uuid",
            readOnly: true,
            displayName: "AD group UUID",
        },
        {
            element: DropdownSelectField,
            name: "groups",
            type: "group"
        },
    ]

}

export class GroupMappingNew extends BaseEditItem {
    FIELDS = [
        {
            element: TextField,
            name: "description",
        },
        {
            element: TextField,
            name: "ad_uuid",
            displayName: "AD group UUID",
            validate: (val) => {
                return String(val).toLowerCase().match(
                    /^[a-zA-Z0-9-]+$/
                ) ? null : "Group UUID can contain only letters, numbers, and dashes.";
            }
        },
        {
            element: DropdownSelectField,
            name: "groups",
            type: "group"
        },
    ]

    getPath() {
        return `${this.props.type}.${this.state.item.ad_uuid}`;
    }
}