import "./PolicyChangeTable.scss";

function PolicyChangeTable(props) {
    let data = JSON.parse(props.value)
    let rows_keys = Object.keys(data)
    let columns = props.columns
    return (
    <div className="table-container">
        <div className="table-container__heading"><span className="table-container__heading__text">CHANGES</span></div>
        <table className="table-container__table">
            <thead className="table-container__table__heading">
                <tr className="table-container__table__heading__row">
                    <th className="table-container__table__heading__row__element--small"/>
                    {columns.map((item) => (
                    <th className="table-container__table__heading__row__element--medium">
                        {item}
                    </th>
                    ))}
                </tr>
            </thead>
            <tbody className="table-container__table__body">
                {rows_keys.map((item) =>
                <tr className="table-container__table__body__row__content">
                    <td className="table-container__table__body__row__content__element--small"> {item} </td>
                    {Object.keys(data[item]).map((item2) =>
                        <td className="table-container__table__body__row__content__element--medium">
                            <div className="table-container__table__body__row__content__element--medium__div">
                                <span className="table-container__table__body__row__content__element--medium__div__span">
                                {data[item][item2]}
                                </span>
                            </div>
                        </td>
                        )}
                </tr>
                )}
            </tbody>
        </table>
    </div>
    )
}

export default PolicyChangeTable
