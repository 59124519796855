import "./PaginationItems.scss"
import { useState } from "react"
const PaginationItems = (props) => {
    const [currentValue,setCurrentValue] = useState(5)
    const clickHandler = (value) => {
        setCurrentValue(value)
        props.onClick(value)
    }
    return(
        <div className="pagination-items">
            <ul className="pagination-items__list">
                <li onClick={()=>clickHandler(5)} className={currentValue===5?"pagination-items__list__item active":"pagination-items__list__item"}>
                    <span className="pagination-items__list__item__text">
                        5
                    </span>
                </li>
                <li onClick={()=>clickHandler(10)} className={currentValue===10?"pagination-items__list__item active":"pagination-items__list__item"}>
                    <span className="pagination-items__list__item__text">
                        10
                    </span>
                </li>
                <li onClick={()=>clickHandler(15)} className={currentValue===15?"pagination-items__list__item active":"pagination-items__list__item"}>
                    <span className="pagination-items__list__item__text">
                        15
                    </span>
                </li>
                <li onClick={()=>clickHandler(20)} className={currentValue===20?"pagination-items__list__item active":"pagination-items__list__item"}>
                    <span className="pagination-items__list__item__text">
                        20
                    </span>
                </li>
            </ul>
        </div>
    )
}

export default PaginationItems