import BaseEditItem from "./BaseEditItem";
import TextField from "../Fields/TextField";
import DateTimePicker from "../Fields/DateTimePicker"
import "./EditItem.scss";
import moment from "moment";
import DropdownSelectField from "../Fields/DropdownSelectField";
import ReadOnlyTextField from "../Fields/ReadOnlyTextField";
import ListReadOnlyField from "../Fields/ListReadOnlyField";


export class PoolUserEdit extends BaseEditItem {
    FIELDS = [
        {
            element: ReadOnlyTextField,
            name: "name",
            displayName: "username",
            readOnly: true
        },
        {
            element: TextField,
            name: "description",
        },
        {
            element: TextField,
            name: "email",
            validate: (email) => {
                return String(email).toLowerCase().match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                ) ? null : "Email is invalid.";
            }
        },
        {
            element: DateTimePicker,
            name: "expires_at",
            displayName: "expires at",
            validate: (time) => {
                const year = moment().add(1, 'year');
                if (moment(time).isAfter(year))
                    return "Temporary session shouldn't be longer than a year";

                if (moment(time) < moment())
                    return "Can't be in the past";
            }
        },
        {
            element: DropdownSelectField,
            name: "groups",
            type: "group"
        },
        {
            element: ListReadOnlyField,
            name: "policies",
            displayName: "Policies",
            genContent: (value) => {
                const result = [];

                for (const i in value) {
                    const item = value[i];

                    result.push(<a href={`../${item.type}/${item.path}`} key={item.path}
                                   className="group-link">{item.name || item.path}</a>)
                }

                return result;
            },
            readOnly: true
        }
    ]
}

export class PoolUserNew extends BaseEditItem {
    FIELDS = [
        {
            element: TextField,
            name: "name",
            displayName: "username",
            validate: (value) => {
                if (String(value).length < 1)
                    return "Can't be empty";

                return String(value).match(/^[a-z0-9._]+$/) ? null : "Username may consist of lower case letters, numbers, dots and underscores."
            }
        },
        {
            element: TextField,
            name: "email",
            validate: (email) => {
                return String(email).toLowerCase().match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                ) ? null : "Email is invalid.";
            }
        },
        {
            element: TextField,
            name: "description",
        },
        {
            element: DateTimePicker,
            name: "expires_at",
            displayName: "expires at",
            validate: (time) => {
                const year = moment().add(1, 'year');
                if (moment(time).isAfter(year))
                    return "Temporary session shouldn't be longer than a year";

                if (moment(time) < moment())
                    return "Can't be in the past";
            }
        },
        {
            element: DropdownSelectField,
            name: "groups",
            type: "group",
        }
    ]

    prepForSave() {
        const result = super.prepForSave();
        result.expires_at = moment(this.state.item.expires_at).unix();
        return result;
    }

    getPath() {
        return `${this.props.type}.${this.state.item.name}`;
    }
}
