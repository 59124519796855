import styled from "styled-components";
import Theme from "./Theme.js";

const Wrapper = styled.div`
    height: ${({theme})=>theme.height};
    width:${({theme})=>theme.width} ;
    min-width:${({theme})=>theme.minWidth} ;
    max-height: ${({theme})=>theme.maxHeight};
    background-color: ${({theme})=>theme.backgroundColor};
    display: ${({theme})=>theme.display};
    justify-content: ${({theme})=>theme.justifyContent};
    align-items: ${({theme})=>theme.alignItems};
    overflow: ${({theme})=>theme.overflow};
    overflow-y: ${({theme})=>theme.overflowY};
    overflow-x: ${({theme})=>theme.overflowX};
`

const Container = ({newTheme,children}) => {
    return (
        <Theme>
            <Wrapper theme={{...newTheme}}>
                {children}
            </Wrapper>
        </Theme>
    )
}

export default Container