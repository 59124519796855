import {AdminSidebar} from "../components/Sidebar/Sidebar";
import React from "react";
import call, {encodeObjectPath} from "../api";
import "pyloncss/css/pylon.css";
import ItemTable from "../components/ItemTable/ItemTable";
import BaseApp from "./BaseApp";

export default class AdminList extends BaseApp {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            content: []
        };
    }

    update() {
        this.setState({content: []});
        this.fetchContent();
    }

    fetchContent() {
        // we're saving the resource type that we will request, so that later we can ignore requests if the prop changed to a different type.
        const dataType = this.props.type;
        call("GET",
            `generic/${dataType}`,
            (status, content) => {
                if (status !== 200) {
                    this.sendNotification("error", "Error while fetching content", content.message, 30);
                    return;
                }

                this.setState((prevState, props) => {
                    // we gotta ignore the requests if they were made before switching tabs (resource typess)
                    // TODO(wkwolek): instead of this workaround, we should cancel the previous request probably, but the whole front end probably needs to be re-thinked
                    if (props.type != dataType) {
                        return {};
                    }
                    return { "content": Object.values(content) };
                })
            });
    }

    /*
     * Render
     */
    getContent() {
        return (<ItemTable
            columns={this.props.columns}
            data={this.state.content}
            title={this.props.title}
            search={this.state.search}
            noCreate={this.props.noCreate}
            onNew={(e) => {
            }}
            onRowClick={(e, item) => {
                window.location.href = `${window.location.href}/${encodeObjectPath(item.path)}`
            }}
        />);
    }

    getSidebar() {
        return (<AdminSidebar
            short={this.state.isMenuShort}
        />);
    }
}

