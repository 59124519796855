import React from "react";
import BaseApp from "../../pages/BaseApp";
import {AdminSidebar} from "../Sidebar/Sidebar";
import call, {encodeObjectPath} from "../../api";
import "./ViewItem.scss";
import "pyloncss/css/pylon.css";
import { useNavigate } from 'react-router-dom';


export default class BaseViewItem extends BaseApp {
    DISPLAY_SEARCH = false;
    CAN_DELETE = false;

    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            item: null,
        };
    }

    update() {
        this.fetchObject();
    }

    fetchObject() {
        if (this.props.create) {
            this.setState({
                "item": {}
            });
            return;
        }

        call("GET",
            `generic/${this.props.type}/${encodeObjectPath(this.props.path)}`,
            (status, content) => {
                if (status === 404) {
                    // TODO 404 page
                    return;
                }

                if (status !== 200) {
                    this.sendNotification("error", "Error while fetching content", content.message, 30);
                    return;
                }
                this.setState({item: content});
            }
        )
    }

    getSidebar() {
        return (<AdminSidebar
            short={this.state.isMenuShort}
        />);
    }

    FIELDS = []

    getContent() {
        if (!this.state.item) {
            return (
                <vstack className="item-view-page" spacing="s">
                    <span className="header">{this.props.name} - Loading...</span>
                </vstack>)
        }

        const fields = [];
        for (const i in this.FIELDS) {
            const field = this.FIELDS[i];
            fields.push(
                <field.element key={field.name}
                               value={this.state.item[field.name]}
                               onChange={(value) => this.updateField(field.name, value)}
                               validation={(value) => this.validate(field, value)}
                               sendNotification={(t, ti, te, ex) => this.sendNotification(t, ti, te, ex)}
                               {...field}
                />
            )
        }

        const delete_style = {display: (this.props.create || !this.CAN_DELETE) ? "none" : null};

        return (
            <vstack className="item-view-page" spacing="s">
                <span
                    className="header">{this.props.name} {this.state.item.name ? this.state.item.name : this.state.item.path}</span>
                <vstack className="item-form" spacing="xxs">
                    {fields}
                </vstack>
                <hstack spacing="s">
                    <BackButton />
                </hstack>
            </vstack>

        );
    }


    getPath() {
        if (!this.props.create)
            return this.props.path;

        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < 16; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return `${this.props.type}.${result}`
    }
}

function BackButton() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };

  return <button className="button button-save" onClick={ handleClick }>Back</button>
}