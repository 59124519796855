import React from "react";
import "pyloncss/css/pylon.css";
import "./Sidebar.scss"
import Icon from "../Icon/Icon";
import {logout} from "../../api";
import {NavLink} from "react-router-dom";


export class UserSidebar extends React.Component {
    render() {
        return (
            <vstack spacing="xs" className="sidebar">
                <NavLink to="/"><SidebarItem name="Dashboard" icon="home" short={this.props.short}/></NavLink>
                {this.props.capabilities.break_glass ?
                    <NavLink to="/break_glass">
                        <SidebarItem name="Break Glass" icon="shield" short={this.props.short}/>
                    </NavLink>
                    : null
                }
                {this.props.capabilities.break_glass ?
                    <NavLink to="/policy_approval">
                        <SidebarItem name="Approve Policy" icon="shield" short={this.props.short}/>
                    </NavLink>
                    : null
                }
                {this.props.onCloseAll ? <SidebarItem name="Close all" icon="x-octagon" short={this.props.short}
                                                      onClick={(e) => this.props.onCloseAll(e)}/> : null}
                <spacer/>
                {this.props.capabilities.admin ?
                    <NavLink to="/admin/console_cfg">
                        <SidebarItem name="Admin" icon="settings" short={this.props.short}/>
                    </NavLink>
                    : null
                }
                
                <SidebarItem name="Log out" icon="log-out" short={this.props.short} onClick={(e) => logout()}/>
            </vstack>
        );
    }
}

export class AdminSidebar extends React.Component {
    render() {
        return (
            <vstack spacing="xs" className="sidebar">
                <NavLink to="/admin/console_cfg"><SidebarItem name="Console apps" icon="box" short={this.props.short}/></NavLink>
                <NavLink to="/admin/bastion_cfg"><SidebarItem name="Bastion apps" icon="cpu" short={this.props.short}/></NavLink>
                <NavLink to="/admin/appstream_cfg"><SidebarItem name="AppStream apps" icon="monitor" short={this.props.short}/></NavLink>
                <NavLink to="/admin/appstream_recording"><SidebarItem name="AppStream recordings" icon="monitor" short={this.props.short}/></NavLink>
                <NavLink to="/admin/policy"><SidebarItem name="Policies" icon="file-text"
                                                         short={this.props.short}/></NavLink>
                <NavLink to="/admin/policy_change"><SidebarItem name="Policy change history" icon="file-text" short={this.props.short}/></NavLink>
                <NavLink to="/admin/resource"><SidebarItem name="Resources" icon="hard-drive" short={this.props.short}/></NavLink>
                <NavLink to="/admin/user-ad"><SidebarItem name="Users" icon="user" short={this.props.short}/></NavLink>
                <NavLink to="/admin/user-pool"><SidebarItem name="Temporary users" icon="user-check"
                                                            short={this.props.short}/></NavLink>
                <NavLink to="/admin/group"><SidebarItem name="Groups" icon="users" short={this.props.short}/></NavLink>
                <NavLink to="/admin/group_mapping"><SidebarItem name="Group mappings" icon="users" short={this.props.short}/></NavLink>
                <spacer/>
                <NavLink to="/" aria-selected={({isActive}) => isActive}><SidebarItem name="Dashboard" icon="home"
                                                                                      short={this.props.short}/></NavLink>
                <SidebarItem name="Log out" icon="log-out" short={this.props.short} onClick={(e) => logout()}/>
            </vstack>
        );
    }
}

export function Logo(props) {
    const long_style = {
        width: props.short ? "60px" : null
    };

    return (
        <vstack className="logo-container" align-y="center">
            <img src={process.env.PUBLIC_URL + "/logo_full.svg"} alt="Logo" className="logo" style={long_style}/>
        </vstack>
    );
}

function SidebarItem(props) {
    const long_style = {
        display: props.short ? 'none' : null,
    }

    const align = props.short ? 'center' : 'left';

    return (
        <hstack className="sidebar-item" aria-selected={props.selected} align-x={align}
                onClick={(e) => props.onClick(e)}>
            <Icon icon={props.icon} className="sidebar-icon"/>
            <span className="sidebar-text" style={long_style}>{props.name}</span>
        </hstack>
    );
}
