import Icon from "../Icon/Icon"
import "./SearchBar.scss"

function SearchBar(props) {

    const onChangeHandler = (event) => {
        props.onChange(event.target.value)
    }
    return(
        <div className="search-bar">
            <div className="search-bar__container">
                <div className="search-bar__container__input">
                    <input onChange={onChangeHandler} className="search-bar__container__input__element" placeholder="Search"/>
                </div>
                <Icon className="search-bar__container__icon" icon="search"/>
            </div>
        </div>
    )
}

export default SearchBar