import BaseViewItem from "./BaseViewItem";
import "./ViewItem.scss";
import call from "../../api";
import ReadOnlyTextField from "../Fields/ReadOnlyTextField";
import PolicyChangeTable from "../ItemTable/PolicyChangeTable";

export default class PolicyChangeHistoryView extends BaseViewItem {
    FIELDS = [
        {
            element: ReadOnlyTextField,
            name: "change_type",
            displayName: "Change",
            readOnly: true
        },
        {
            element: ReadOnlyTextField,
            name: "date_change",
            displayName: "date",
            readOnly: true
        },
        {
            element: ReadOnlyTextField,
            name: "user",
            displayName: "user",
            readOnly: true
        },
        {
            element: ReadOnlyTextField,
            name: "name",
            displayName: "policy name",
            readOnly: true
        },
        {
            element: ReadOnlyTextField,
            name: "policy",
            displayName: "Policy",
            readOnly: true
        },
        {
            element: PolicyChangeTable,
            name: "changes",
            columns: ["old", "new"],
            readOnly: true
        }
    ]
}
