import React from "react";
import "pyloncss/css/pylon.css";
import {CopyButton, LinkButton, LoadingButon, PlayButton, StopButton, TerminalButton, ForwardButton} from "../IconButton/IconButton";
import Icon from "../Icon/Icon";
import "./LaunchConfig.scss"


function DescriptionRow(props) {
    return [
        <div className="key" key={props.name}>{props.name}:</div>,
        <div className="value" key={props.value}>{props.value}</div>
    ]
}


export class ConsoleLaunchConfig extends React.Component {
    icon = "box";

    getDetails() {
        return (
            <div className="details-container">
                <DescriptionRow name="Account" value={this.props.accountName}/>
                <DescriptionRow name="Policies" value={this.props.policies}/>
            </div>
        )
    }

    getTime() {
        if (this.props.time)
            return (
                <span className="time-container"><span className="time-header">Time remaining: </span>{this.props.time}</span>
            )
    }

    getIcons() {
        if (this.props.state === "stopped") {
            return [
                <PlayButton key="play" onClick={(e) => this.props.onAppStart(e)}/>,
            ];
        } else if (this.props.state === "starting") {
            return [
                <LoadingButon key="loading"/>
            ];
        } else if (this.props.state === "running") {
            return [
                <LinkButton key="link" onClick={(e) => this.props.onAppOpen(e)}/>,
                <CopyButton key="copy" onClick={(e) => this.props.onAppCopy(e)}/>,
                <StopButton key="stop" onClick={(e) => this.props.onAppStop(e)}/>
            ];
        }

        return [];
    }

    render() {
        const description_style = {
            display: this.props.description ? null : "none",
        };

        let time;
        if (this.props.state === "running")
            time = this.getTime()

        return (
            <vstack className="launch-config"
                    onClick={() => this.props.onClick()}
                    aria-selected={this.props.open}
            >
                <hstack align-y="center" spacing="xs">
                    <span className="icon-type"><Icon icon={this.icon}/></span>
                    <span className="name">{this.props.name}</span>
                    <spacer/>
                    <span className="account">{this.props.accountName}</span>
                    {this.getIcons()}
                </hstack>
                <div style={description_style} className="description-container">
                    <span className="description">{this.props.description}</span>
                </div>
                {this.getDetails()}
                {time}
            </vstack>

        );
    }
}

export class BastionLaunchConfig extends ConsoleLaunchConfig {
    icon = 'cpu';

    getDetails() {
        return (
            <div className="details-container">
                <DescriptionRow name="Account" value={this.props.accountName}/>
                <DescriptionRow name="Policies" value={this.props.policies}/>
                <DescriptionRow name="Resources" value={this.props.resources}/>
            </div>
        )
    }

    getIcons() {
        if (this.props.state === "stopped") {
            return [
                <PlayButton key="play" onClick={(e) => this.props.onAppStart(e)}/>
            ];
        } else if (this.props.state === "starting") {
            return [
                <LoadingButon key="loading"/>
            ];
        } else if (this.props.state === "running") {
            return [
                <TerminalButton key="terminal" onClick={(e) => this.props.onAppOpen(e)}/>,
                <ForwardButton key="forward" onClick={(e) => this.props.onAppCopy(e)}/>,
                <StopButton key="stop" onClick={(e) => this.props.onAppStop(e)}/>
            ];
        }

        return [];
    }
}

export class AppStreamLaunchConfig extends ConsoleLaunchConfig {
    icon = 'monitor';

    getDetails() {
        return (
            <div className="details-container">
                <DescriptionRow name="Account" value={this.props.accountName}/>
            </div>
        )
    }

    getIcons() {
        if (this.props.state === "stopped") {
            return [
                <PlayButton key="play" onClick={(e) => this.props.onAppStart(e)}/>
            ];
        } else if (this.props.state === "starting") {
            return [
                <LoadingButon key="loading"/>
            ];
        } else if (this.props.state === "running") {
            return [
                <LinkButton key="link" onClick={(e) => this.props.onAppOpen(e)}/>,
                <StopButton key="stop" onClick={(e) => this.props.onAppStop(e)}/>
            ];
        }

        return [];
    }
}

export class BreakGlassLaunchConfig extends ConsoleLaunchConfig {
    icon = "user";

    getDetails() {
        return (
            <div className="details-container">
                <DescriptionRow name="Name" value={this.props.name}/>
                <DescriptionRow name="Email" value={this.props.email}/>
                <DescriptionRow name="Applications" value={this.props.applications}/>
                <DescriptionRow name="Time remaining" value={this.props.time}/>
            </div>
        )
    }

    getIcons() {
        return (
            <StopButton key="stop" onClick={(e) => this.props.onAppStop(e)}/>
        );
    }
}