import React from "react";
import "./Fields.scss";


export default class UnsignedUrlsField extends React.Component {
    render() {
        const value = this.props.value ? this.props.value : [];
        const content = this.props.genContent ? this.props.genContent(value) : value.join(", ")

        return (
            <hstack className="field-group__links" spacing="s" align-y="top">
                <span className="field-label">{this.props.displayName ? this.props.displayName : this.props.name}</span>
                <div className="field-group__links__container">{value.map(item => (
        <a className="field-group__links__container__item" href={item}>
            {item.match("[^/]+\\.mp4")[0]}
        </a>
        ))}</div>
            </hstack>
        )
    }
}
