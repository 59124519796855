import BaseEditItem from "./BaseEditItem";
import TextField from "../Fields/TextField";
import "./EditItem.scss";
import DropdownSelectField from "../Fields/DropdownSelectField";
import ListReadOnlyField from "../Fields/ListReadOnlyField";


export default class AdUserEdit extends BaseEditItem {
    CAN_DELETE = false;

    FIELDS = [
        {
            element: TextField,
            name: "name",
            // validate: (val) => {
            //     if (val.search(" ") !== -1)
            //         return "Can't use spaces."
            // }
        },
        {
            element: TextField,
            name: "description",
        },
        {
            element: DropdownSelectField,
            name: "groups",
            type: "group"
        },
        {
            element: ListReadOnlyField,
            name: "policies",
            displayName: "Policies",
            genContent: (value) => {
                const result = [];

                for (const i in value) {
                    const item = value[i];

                    result.push(<a href={`../${item.type}/${item.path}`} key={item.path}
                                   className="group-link">{item.name || item.path}</a>)
                }

                return result;
            },
            readOnly: true
        }
    ]
}