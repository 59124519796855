import BaseViewItem from "./BaseViewItem";
import TextField from "../Fields/TextField";
import "./ViewItem.scss";
import DropdownSelectField, {SingleItemDropdownField, ResourceSelectField} from "../Fields/DropdownSelectField";
import call from "../../api";
import ReadOnlyTextField from "../Fields/ReadOnlyTextField";
import UnsignedUrlsField from "../Fields/UnsignedUrlsField";


export default class AppStreamRecordingView extends BaseViewItem {
    FIELDS = [
        {
            element: ReadOnlyTextField,
            name: "file",
            displayName: "file",
            readOnly: true
        },
        {
            element: ReadOnlyTextField,
            name: "username",
            displayName: "username",
            readOnly: true
        },
        {
            element: ReadOnlyTextField,
            name: "appstream_session_id",
            displayName: "appstream session id",
            readOnly: true
        },
        {
            element: ReadOnlyTextField,
            name: "appstream_scripts",
            displayName: "appstream scripts",
            readOnly: true
        },
        {
            element: ReadOnlyTextField,
            name: "date",
            displayName: "date",
            readOnly: true
        },
        {
            element: UnsignedUrlsField,
            name: "presigned_urls",
            displayName: "Download files",
            readOnly: true
        }
    ]
}
