import styled from 'styled-components';
import Container from "../Container";

const ContainerTheme = {
    width: '100%',
    height: "40vh",
    display: 'flex',
    justifyContent: "space-between"
}

const defaultLabelWrapperTheme = {
    height: '10%',
    width: '20%',
    display: 'flex',
    alignItems: 'center'
}

const defaultContentWrapperTheme = {
    minWidth: '20%',
    width: '80%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    overflowY: 'scroll'
}

const SpanElement = styled.span`
    white-space: pre;
    font-size: ${({theme})=>theme.fontSize};
    font-weight: ${({theme})=>theme.fontWeight};
`

const ReadOnlyTextFieldNew = (props) => {
    return props.value?(
        <Container newTheme={ContainerTheme}>
            {props.displayName?<Container newTheme={props.theme ? props.theme : defaultLabelWrapperTheme}>
                <SpanElement theme={{fontSize: '1rem'}}>
                    {props.displayName}
                </SpanElement>
            </Container>:null}
            <Container newTheme={props.theme ? props.theme : defaultContentWrapperTheme}>
                <SpanElement>
                    {props.value}
                </SpanElement>
            </Container>
        </Container>
    ):null
}

export default ReadOnlyTextFieldNew