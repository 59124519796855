import BaseEditItem from "./BaseEditItem";
import TextField, {TextArea} from "../Fields/TextField";
import ReadOnlyTextField from "../Fields/ReadOnlyTextField"
import ReadOnlyTextFieldNew from "../Fields/ReadOnlyTextFieldNew";
import "./EditItem.scss";
import PolicyHistoryTable from "../ItemTable/PolicyHistoryTable";


export default class PolicyEdit extends BaseEditItem {
    FIELDS = [
        {
            element: TextField,
            name: "name",
        },
        {
            element: TextField,
            name: "description",
        },
        {
            element: ReadOnlyTextField,
            name: "status",
        },
        {
            element: TextArea,
            name: "policy",
            displayName: "Inline Policy",
            validate: (value) => {
                if (!value)
                    return "Can't be empty"

                try {
                    JSON.parse(value);
                } catch (e) {
                    return "Invalid JSON."
                }
            }
        },
        {
            element: TextField,
            name: "arn",
            displayName: "AWS Managed Policy ARN",
            validate: (value) => {
                if (!value)
                    return "Can't be empty";

                if (!value.startsWith("arn:aws:iam::aws:policy/"))
                    return "Must start with 'arn:aws:iam::aws:policy/'";
            }
        },
        {
            element: ReadOnlyTextFieldNew,
            name: "AWSManagedJSON",
            displayName: "AWS Managed Policy Preview"
        },
        {
            element: PolicyHistoryTable,
            name: "history",
            columns: ["Change Type", "Date", "User"],
            fields_to_extract: ["change_type", "date_change", "user"],
            readOnly: true,
        }
    ]

    validate(field, value) {
        console.log(value)
        if (field.name === "policy" || field.name === "arn")
            if (this.state.item.policy && this.state.item.arn)
                return "A policy can not have a managed ARN and an inline policy at the same time."

        if (this.state.item.arn && field.name === "policy")
            return;

        if (this.state.item.policy && field.name === "arn")
            return;
    
        if (this.state.item.AWSManagedJSON && field.name === "AWSManagedJSON")
            return;
        if (this.state.item.history && field.name==="history")
            return;

        return super.validate(field, value);
    }
}
