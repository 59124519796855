import { ThemeProvider } from "styled-components"

const theme ={
    height: 'auto',
    maxHeight: "20rem",
    width: 'auto',
    minWidth: 'auto',
    maxWidth: '100vw',
    backgroundColor: 'red',
    display: 'block',
    flexWrap: 'wrap',
    justifyContent: 'initial',
    alignItems: 'initial',
    overflow: 'visible',
    overflowY: 'visible',
    overflowX: 'visible',
    fontSize: '0.9rem',
    fontWeight: '300',
    labels: {
        textAlign: "center"
    }
}

export default function Theme ({children}) {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}