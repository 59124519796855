import "./PolicyHistoryTable.scss";

import Pagination from "../Pagination/Pagination"
import { useState } from "react";
import { useNavigate,useLocation } from 'react-router-dom';

function PolicyHistoryTable(props) {
    let data = props.value
    let columns = props.columns
    let rows_keys = props.fields_to_extract

    const navigate = useNavigate();
    let { pathname } = useLocation();
    pathname = pathname.replace(/\/[^/]+.[^/]+$/, '/policy_change/');
    let handleClick = (pol_id) => {
        navigate(pathname + pol_id);
    };

    const getRows = () => {
        const results = []
        for(let [index,value] of data.entries()) {
            results.push(<tr className="table-container__table__body__row" onClick={(e,item) => handleClick(value["path"])}>
                {rows_keys.map((key)=>(
                    <td className="table-container__table__body__row__element--medium">
                        {value[key]}
                    </td>
                ))}
            </tr>)
        }
        return results
    } 
    const rows = getRows()
    const [pagination,setPagination] = useState(
        {
            page: 1,
            last_index: 0,
            items: 8,
            next_index: 8
        }
    )
    const pagination_items = Math.ceil(getRows().length / pagination.items)
    const pageHandler = (newValue) => {
        const itemsOnPage = pagination.items
        const prevPage = newValue-1
        const newPage = newValue
        const prevIndex = prevPage*itemsOnPage
        const nextIndex = newPage*itemsOnPage
        setPagination((prevState)=>({
            page: newPage,
            last_index: prevIndex,
            items: prevState.items,
            next_index: nextIndex
        }));
    }
    return data ? (
    <div className="table-container">
        <div className="table-container__heading"><span className="table-container__heading__title">CHANGES</span></div>
        <table className="table-container__table" >
            <thead className="table-container__table__heading">
                <tr className="table-container__table__heading__row">
                    {columns.map((item) => (
                    <th className="table-container__table__heading__row__element--medium">
                        {item}
                    </th>
                    ))}
                </tr>
            </thead>
            <tbody className="table-container__table__body">
                {getRows().slice(pagination.last_index, pagination.next_index)}
            </tbody>
        </table>
        <div className="table-container__pagination">
            <Pagination items={pagination_items} pageHandler={pageHandler} />  
        </div>
    </div>
    ) : null
}

export default PolicyHistoryTable