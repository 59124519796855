import {UserSidebar} from "../components/Sidebar/Sidebar";
import call, {encodeObjectPath} from "../api";
import React from "react";
import BaseApp from "./BaseApp";
import ItemTable from "../components/ItemTable/ItemTable";

export default class PolicyApproval extends BaseApp {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            items: []
        };
    }

    getPolicies(){
        console.log("XD")
        call("GET",
            `generic/policy`,
            (status, content) => {
                if (status === 404) {
                    // TODO 404 page
                    return;
                }

                if (status !== 200) {
                    this.sendNotification("error", "Error while fetching content", content.message, 30);
                    return;
                }
                this.setState({isLoading:false, items: Object.values(content).filter((value)=>value.status==="Pending")});
            }
        )
    }
    update() {
        this.getPolicies()
    }
    /*
     * Render
     */
    
    getSidebar() {
        return (
            <UserSidebar
                short={this.state.isMenuShort}
                onCloseAll={(e) => this.onCloseAll(e)}
                capabilities={this.getCapabilities()}
            />)
    }

    getContent() {
        return (
            <div className="policy-approval__container">
                <ItemTable
                    columns={["name", "status"]}
                    data={this.state.items}
                    title={"Waiting for approve"}
                    noCreate={true}
                    onRowClick={(e,item)=>{window.location.href = `admin/policy/${encodeObjectPath(item.path)}`}}
                />
            </div>
        )
    }
}