import patchFetch from "fetch-retry";
const fetch = patchFetch(window.fetch);

/* global API_URL, COGNITO_DOMAIN, COGNITO_APP_ID, DEBUG, ID_TOKEN */

function login_url() {
    const app_url = `${window.location.protocol}//${window.location.hostname}`;
    return `https://${COGNITO_DOMAIN}/login?response_type=token&client_id=${COGNITO_APP_ID}&redirect_uri=${encodeURIComponent(app_url)}`;
}

function logout_url() {
    const app_url = `${window.location.protocol}//${window.location.hostname}`;
    return `https://${COGNITO_DOMAIN}/logout?response_type=token&client_id=${COGNITO_APP_ID}&redirect_uri=${encodeURIComponent(app_url)}`;
}

export function goToLogin() {
    if (!DEBUG) {
        window.location = login_url();
    }
}

export function logout() {
    if (!DEBUG) {
        localStorage.clear();
        window.location = logout_url();
    }
}

export function get_auth() {
    if (!DEBUG)
        return localStorage.getItem("id_token");

    return ID_TOKEN;
}

function build_url(suffix) {
    return `${API_URL}/${suffix}`;
}

export function encodeObjectPath(path) {
    return encodeURIComponent(path)
}

export default async function call(method, url_path, callback, payload = {}) {
    const token = get_auth();
    DEBUG && console.log("API call", method, url_path, payload, "auth=", token == null ? token : `${token.substring(0, 10)}...`);

    const options = {
        method: method,
        headers: {
            "Authorization": token,
        },
        retries: 5,
        retryOn: [500, 429],
        retryDelay: function(attempt, error, response) {
            return Math.pow(2, attempt) * 200; // exponential backoff
        },
    }

    if (method !== "GET") {
        options.body = JSON.stringify(payload);
        options.headers["Content-Type"] = "application/json";
    }

    try {
        const response = await fetch(build_url(url_path), options);
        if(response.status === 401) {
            goToLogin();
            return;
        }

        const json = await response.json();

        // TODO(wkwolek): call() API consumers should be rewritten to use promises/async-await
        callback(response.status, json);
    } catch (e) {
        // TODO(wkwolek): Handle errors
        console.error(method, url_path, e);
        return;
    }
}